import { useEffect, useState } from "react";
import { PageHeader } from "../../components/Base/PageHeader";
import SearchComponent from "../../components/SearchComponent";
import { Icon } from "@iconify/react";
import { Select } from "../../components/Base/Select";
import Pagination from "../../components/Pagination";
import TableLoadingState from "../../components/TableLoadingState";
import useCurrency from "../../hooks/useCurrency";
import { Link, useNavigate } from "react-router-dom";
import {
  FetchQuoteParams,
  fetchQuotes,
  Quote,
} from "../../features/quote/service";
import { isEqual } from "lodash";
import moment from "moment";
import {
  StatusViews,
  StatusViewValue,
} from "../../components/ServiceQuote/data";

const orderByList = [
  { value: "DESC", text: "Most Recent" },
  { value: "ASC", text: "Created First" },
];

function ServiceQuote() {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [quotes, setQuotes] = useState<Quote[]>([]);
  const [orderBy, setOrderBy] = useState<"DESC" | "ASC">("DESC");
  const [activeStatusView, setActiveStatusView] = useState(
    StatusViews[0].value
  );
  const [activeStatusViewHeaders, setActiveStatusViewHeaders] = useState<
    string[]
  >(StatusViews[0].headers);
  const [whereQuery, setWhereQuery] = useState(StatusViews[0].where);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState(1);
  const getQuotes = async () => {
    try {
      setLoading(true);
      const fetchQuoteParams: FetchQuoteParams = {
        where: whereQuery,
        limit: 10,
        page: currentPage,
        keyword,
        order: orderBy,
      };

      const {
        data,
        metadata: {
          pagination: { totalCount, totalPages },
        },
      } = await fetchQuotes(fetchQuoteParams);
      setQuotes(data);
      setTotalRecords(totalCount);
      setTotalPage(totalPages);
    } catch (error) {
      console.error("Error fetching quotes:", error);
    } finally {
      setLoading(false);
    }
  };

  const getStatusViewQuotes = (quotes: Quote[]) => {
    return quotes.map((quote) => {
      const {
        customer,
        services,
        budget,
        requestedDate,
        requestedTime,
        price,
        date,
        timeSlot,
        createdAt,
        updatedAt,
        staff,
        id,
      } = quote;
      const service = services[0];
      if (activeStatusView === "pending-action") {
        return {
          id,
          customerName: customer.fullName,
          serviceName: service.service?.label ?? service.description,
          others: service.others ? (
            <Icon icon="pixelarticons:check" width="22" height="22" />
          ) : (
            <Icon icon="memory:cancel" width="22" height="22" />
          ),
          budget: useCurrency(budget || 0),
          time:
            requestedTime &&
            moment(`1970-01-01T${requestedTime}`).format("LTS"),
          date: requestedDate && moment(requestedDate).format("Do MMM YYYY"),
          createdOn: moment(createdAt).format("Do MMM YYYY"),
          modifiedOn: moment(updatedAt).calendar(),
        };
      } else if (
        activeStatusView === "pending-customer-action" ||
        activeStatusView === "pending-merchant-finalization"
      ) {
        return {
          id,
          customerName: customer.fullName,
          serviceName: service.service?.label ?? service.description,
          staff:
            staff && staff.firstName ? (
              `${staff.firstName} ${staff.lastName}`
            ) : (
              <Icon icon="memory:cancel" width="22" height="22" />
            ),
          price: useCurrency(price || 0),
          timeSlot,
          appointmentDate: moment(date).format("MMMM Do YYYY"),
          modifiedOn: moment(updatedAt).calendar(),
        };
      } else {
        return {};
      }
    });
  };

  useEffect(() => {
    getQuotes();
  }, [keyword, currentPage, whereQuery, orderBy]);

  useEffect(() => {
    const view = StatusViews.find((_view) => _view.value === activeStatusView);
    if (view && !isEqual(view.where, whereQuery)) {
      setActiveStatusViewHeaders(view.headers);
      setWhereQuery(view.where);
    }
  }, [activeStatusView]);

  return (
    <div className="h-screen flex flex-col gap-2">
      <PageHeader text={"Service Quote"} />
      <div>
        {/* Create Quote Section */}
        <div className="flex justify-end w-full">
          <Link to="/dashboard/service-quote/create">
            <button className="bg-[#8B0B0B] p-2 text-white flex gap-2 items-center text-center rounded-lg">
              <Icon icon={"solar:document-outline"} />
              Create Quote
            </button>
          </Link>
        </div>
        {/* Quote Table Parameter */}
        <div className="flex flex-wrap items-center justify-between pt-5 space-y-4 lg:space-y-0 mb-3">
          <div className="md:w-1/4">
            <SearchComponent search={setKeyword} />
          </div>
          <div className="ml-auto mr-10 flex items-center gap-4">
            {/* <div className="rounded border-solid border-[1px] p-2 flex text-center justify-center gap-4 text-sm">
              <span>Awaiting Finalization</span>
              <span className={"bg-red-700 rounded badge h-full"}>
                {quotes.filter((q) => q.status === "pending").length}
              </span>
            </div> */}
            <Select
              options={StatusViews}
              value={activeStatusView}
              width="w-max-[14rem] w-full h-2"
              onChange={(e) =>
                setActiveStatusView(e.target.value as StatusViewValue)
              }
            />
            <Select
              options={orderByList}
              value={orderBy}
              width="w-[11rem] h-2"
              onChange={(e) => setOrderBy(e.target.value as "DESC" | "ASC")}
            />
          </div>
        </div>
        {/* Quote Table */}
        <div className="inline-block w-full overflow-x-scroll border rounded-lg">
          <div className="max-h-[68vh] overflow-auto">
            <table className="w-full text-sm text-left bg-white">
              <thead className="w-fit text-[11px] sm:text-xs font-semibold text-white md:text-sm bg-main">
                <tr>
                  {activeStatusViewHeaders.map((header) => (
                    <th scope="col" className="px-6 py-3" key={header}>
                      {header}
                    </th>
                  ))}
                  {/* <th scope="col" className="px-6 py-3">
                    Service
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Staff
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Price
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" className="flex items-center px-6 py-3">
                    Time | Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Last Modified
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Date Created
                  </th> */}
                </tr>
              </thead>

              {loading ? (
                <TableLoadingState text="Getting Quotes..." fetching />
              ) : !quotes.length ? (
                <TableLoadingState text="No Quotes Found" />
              ) : (
                <tbody>
                  {getStatusViewQuotes(quotes).map((content, index) => (
                    <tr
                      className="sm:text-xs text-[11px] border-t md:text-[13px] cursor-pointer"
                      key={content.id}
                      onClick={() =>
                        navigate(`/dashboard/service-quote/${content.id}`)
                      }
                    >
                      {Object.values(content).map((value, index) => (
                        <td
                          className="px-4 py-2 md:px-6 md:py-4 whitespace-nowrap"
                          key={index}
                        >
                          {value ?? "-"}
                        </td>
                      ))}
                      {/* <th
                        scope="row"
                        className="px-4 py-2 font-medium text-gray-900 md:px-6 md:py-4 whitespace-nowrap"
                      >
                        {content.customer?.firstName}{" "}
                        {content.customer?.lastName}
                      </th>
                      <td className="px-4 py-2 md:px-6 md:py-4">
                        {content.services
                          ?.map((s) => s.description)
                          .join(", ") || "N/A"}
                      </td>
                      <td className="px-4 py-2 md:px-6 md:py-4">
                        {content.staffId || "---"}
                      </td>
                      <td className="px-4 py-2 md:px-6 md:py-4">
                        {content.budget ? useCurrency(content.budget) : "---"}
                      </td>
                      <td className="px-4 py-2 md:px-6 md:py-4">
                        <div
                          className={`border-2 whitespace-nowrap font-semibold text-xs
                          ${
                            content.status === "finalized"
                              ? "bg-green-400 border-green-500"
                              : content.status === "pending"
                              ? "bg-orange-200 border-orange-500"
                              : "bg-gray-300 border-gray-400"
                          } rounded-full p-1 px-2 text-center`}
                        >
                          {content.status.toUpperCase()}
                        </div>
                      </td>
                      <td className="px-4 py-2 md:px-6 md:py-4">
                        {content.timeSlot?.split("-")[0].trim()} |{" "}
                        {new Date(content.date).toISOString().split("T")[0]}
                      </td>
                      <td className="px-4 py-2 md:px-6 md:py-4">
                        {new Date(content.updatedAt).toLocaleDateString()}
                      </td>
                      <td className="px-4 py-2 md:px-6 md:py-4">
                        {new Date(content.createdAt).toLocaleDateString()}
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
          {/* Quote Table Pagination */}
          <div className="px-4 py-2 md:px-6 md:py-4 border-t">
            <Pagination
              next={() => {
                if (currentPage < totalPages) {
                  setCurrentPage(currentPage + 1);
                }
              }}
              prev={() => {
                if (currentPage > 1) {
                  setCurrentPage(currentPage - 1);
                }
              }}
              totalRecords={totalRecords}
              currentPage={currentPage}
              limit={10}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceQuote;
