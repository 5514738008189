import axios from "axios";

export interface GetAvailableSlotParams {
  serviceId: number | string;
  date: string;
}

export interface GetAvailableSlotResponse {
  message: string;
  data: string[];
}

export const getAvailableSlots = async (params: GetAvailableSlotParams) => {
  const { serviceId, date } = params;
  const { data } = await axios.get<GetAvailableSlotResponse>(
    `/api/business/operations/slots?serviceId=${serviceId}&date=${date}`
  );
  return data;
};
