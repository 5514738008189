import { Quote } from "../../features/quote/service";
import moment from "moment";

interface Props {
  quote: Quote;
}

function Logs({ quote }: Props) {
  return (
    <div className="w-full bg-white p-6 rounded-2xl shadow-lg">
      <span className="text-xl font-bold mb-8">Logs</span>
      <div className="relative">
        {quote.logs.map((log, index) => (
          <div key={index} className="flex gap-4 items-start relative mb-6">
            {/* Vertical line connecting logs */}
            {index !== quote.logs.length - 1 && (
              <div className="absolute left-[25px] top-[40px] h-full w-[2px] bg-black"></div>
            )}

            {/* Log Icon */}
            <div className="relative z-10 flex items-center justify-center p-3 rounded-full bg-gray-100 border">
              <span>📄</span>
            </div>

            {/* Log Details */}
            <div>
              <p className="text-sm font-semibold capitalize">
                Invoice was {log.action} by {log.recipient}
              </p>
              <p className="text-xs text-gray-600">
                {moment(log.createdAt).format("DD MMMM YYYY, HH:mm")}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Logs;
