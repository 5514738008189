import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ModalContainer from "../ModalContainer";

interface ClientModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAddClient: (client: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  }) => void;
}

const ClientModal: React.FC<ClientModalProps> = ({
  isOpen,
  onClose,
  onAddClient,
}) => {
  // Validation Schema
  const ClientSchema = Yup.object().shape({
    firstName: Yup.string().trim().required("First Name is required"),
    lastName: Yup.string().trim().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    phone: Yup.string()
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
      .required("Phone number is required"),
  });

  return (
    <ModalContainer modalOpen={isOpen} closeModal={onClose} size="large">
      <div className="p-6 flex flex-col items-center gap-4 bg-gray-100 rounded-xl shadow-lg">
        {/* Close Button */}
        <button
          className="absolute top-4 right-4 text-gray-500 text-xl"
          onClick={onClose}
        >
          ×
        </button>

        {/* Form Container */}
        <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md">
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              phone: "",
            }}
            validationSchema={ClientSchema}
            onSubmit={(values) => {
              onAddClient(values);
              onClose();
            }}
          >
            {({ isSubmitting }) => (
              <Form className="flex flex-col gap-4">
                {/* First Name & Last Name */}
                <div className="flex gap-3">
                  <div className="flex flex-col w-1/2">
                    <label className="text-sm font-medium">First Name</label>
                    <Field
                      type="text"
                      name="firstName"
                      className="border bg-gray-200 rounded-lg px-3 py-2 w-full"
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                  <div className="flex flex-col w-1/2">
                    <label className="text-sm font-medium">Last Name</label>
                    <Field
                      type="text"
                      name="lastName"
                      className="border bg-gray-200 rounded-lg px-3 py-2 w-full"
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                </div>

                {/* Email Field */}
                <div className="flex flex-col">
                  <label className="text-sm font-medium">Email Address</label>
                  <Field
                    type="email"
                    name="email"
                    className="border bg-gray-200 rounded-lg px-3 py-2 w-full"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                {/* Phone Field */}
                <div className="flex flex-col">
                  <label className="text-sm font-medium">Phone Number</label>
                  <Field
                    type="tel"
                    name="phone"
                    className="border bg-gray-200 rounded-lg px-3 py-2 w-full"
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                {/* Confirm Button */}
                <div className="flex justify-center mt-4">
                  <button
                    type="submit"
                    className="px-6 py-2 bg-green-600 text-white rounded-lg font-semibold hover:bg-green-700"
                    disabled={isSubmitting}
                  >
                    CONFIRM
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ClientModal;
