import { createSlice } from "@reduxjs/toolkit";
import { CRMData, getClients } from "./service";
import { RootState } from "../../store/store";

interface InitialState {
  customers: CRMData[];
  fetching: boolean;
}

const initialState: InitialState = {
  customers: [],
  fetching: false,
};

export const crmSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getClients.pending, (state, action) => {
      state.fetching = true;
    });
    builder.addCase(getClients.rejected, (state, action) => {
      state.fetching = false;
    });
    builder.addCase(getClients.fulfilled, (state, action) => {
      state.fetching = false;
      state.customers = action.payload;
    });
  },
});

// Other code such as selectors can use the imported `RootState` type
export const selectCustomers = (state: RootState) => state.crm.customers;

export default crmSlice.reducer;
