import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export interface Staff {
  id: 18;
  active: true;
  role: "staff";
  email: "james+3@buypower.ng";
  firstName: "Levi";
  lastName: "Christiansen";
  phone: "870-714-2747";
  profilePic: "http://placeimg.com/640/480";
  businessId: 14;
  userId: 7;
  appointmentCount: 3;
}

interface GetStaffResponse {
  message: "Staffs retrieved successfully";
  data: Staff[];
}

export const getStaffs = createAsyncThunk(
  "staff/getStaffs",
  async (_, thunkAPI) => {
    try {
      const {
        data: { data },
      } = await axios.get<GetStaffResponse>(`/api/staff`);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
