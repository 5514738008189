import { QuoteStatus } from "../../features/quote/service";
import * as Yup from "yup";
import { ServiceQuoteFormI } from "./QuotePreview";

export type StatusViewValue =
  | "pending-action"
  | "pending-customer-action"
  | "pending-merchant-finalization";
export const StatusViews = [
  {
    text: "Pending Action",
    value: "pending-action" as StatusViewValue,
    where: {
      merchantStatus: QuoteStatus.Pending,
      status: [QuoteStatus.Modified, QuoteStatus.Created],
    },
    headers: [
      "#",
      "Customer Name",
      "Service",
      "Other Services",
      "Budget",
      "Requested Time",
      "Requested Date",
      "Created On",
      "Last Modified",
    ],
  },
  {
    text: "Pending Customer Action",
    value: "pending-customer-action" as StatusViewValue,
    where: {
      merchantStatus: [QuoteStatus.Modified, QuoteStatus.Created],
      status: [QuoteStatus.Pending, QuoteStatus.Modified],
    },
    headers: [
      "#",
      "Name",
      "Service",
      "Staff",
      "Price",
      "Time Slot",
      "Appointment Date",
      "Last Modified",
    ],
  },
  {
    text: "Pending Merchant Finalization",
    value: "pending-merchant-finalization" as StatusViewValue,
    where: {
      status: QuoteStatus.Finalized,
      merchantStatus: [
        QuoteStatus.Modified,
        QuoteStatus.Created,
        QuoteStatus.Pending,
      ],
    },
    headers: [
      "#",
      "Name",
      "Service",
      "Staff",
      "Price",
      "Time Slot",
      "Appointment Date",
      "Last Modified",
    ],
  },
];

export const ServiceQuoteFormSchema = Yup.object().shape({
  service: Yup.number().required("Service Selection is Required"),
  client: Yup.string().required("Client is required"),
  calendar: Yup.date().required("Date is required"),
  timeSlot: Yup.string().required("Time slot is required"),
  price: Yup.number().required("Price is required").positive(),
  discount: Yup.string().when("applyDiscount", {
    is: true,
    then: Yup.string().required("Discount is required"),
  }),
  taxRate: Yup.string().when("applyTaxRate", {
    is: true,
    then: Yup.string().required("Tax rate is required"),
  }),
});

export const ServiceQuoteFormInitValues: ServiceQuoteFormI = {
  service: 0,
  clientName: "",
  clientLastName: "",
  clientEmail: "",
  clientPhone: "",
  calendar: "",
  staff: 0,
  timeSlot: "",
  price: 0,
  applyDiscount: false,
  discount: 0,
  applyTaxRate: false,
  taxRate: 0,
  notes: "",
  paymentDescription: "",
};
