import React from "react";
import AppointmentBanner from "../components/AppointmentBanner";
import { useLocation } from "react-router-dom";
import { Navigation } from "../components/Navigation/Navigation";
import StatisticsBanner from "../components/StatisticsBanner";
import { useAppSelector } from "../store/hooks";
import StaffAppointmentBanner from "../components/StaffAppointmentBanner";
import LoyaltyProgramBanner from "../components/LoyaltyProgramBanner";
interface Props {
  children?: React.ReactNode;
}
const Dashboard: React.FC<Props> = (props: Props) => {
  const location = useLocation();
  const userRole = useAppSelector((state) => state.auth.role);
  return (
    <div className="flex w-full h-screen overflow-x-hidden overflow-y-auto">
      <Navigation />
      <div className="w-full p-4 bg-white md:p-8 bg-pattern">
        <div
          className={`${
            userRole === "staff" &&
            location.pathname !== "/dashboard/appointments"
              ? " flex justify-between "
              : [
                  "/dashboard/home",
                  "/dashboard/service",
                  "/dashboard/schedule",
                  "/dashboard/reviews",
                  "/dashboard/appointments",
                  "/dashboard/gallery",
                  "/dashboard/faq",
                  "/dashboard/businessinfo",
                  "/dashboard/businessinfo/documentation",
                  "/dashboard/businessinfo/customization",
                  "/dashboard/businessinfo/subscription",
                  "/dashboard/businessinfo/ownerdetails",
                  "/dashboard/loyalty-program/how-to-redeem/voucher",
                ].includes(location.pathname) &&
                " grid grid-cols-3 md:grid-cols-4 lg:space-x-10 "
          }`}
        >
          {userRole !== "staff" ? (
            <div className="col-span-4 space-y-5 sm:col-span-2 md:col-span-3 md:space-y-10">
              {props.children}
            </div>
          ) : location.pathname === "/dashboard/appointments" ? (
            <div className="col-span-4 space-y-5 sm:col-span-2 md:col-span-3 md:space-y-10">
              {props.children}
            </div>
          ) : (
            <StaffAppointmentBanner />
          )}

          {[
            "/dashboard/home",
            "/dashboard/service",
            "/dashboard/schedule",
            "/dashboard/reviews",
            "/dashboard/appointments",
            "/dashboard/gallery",
            "/dashboard/faq",
            "/dashboard/businessinfo",
            "/dashboard/businessinfo/documentation",
            "/dashboard/businessinfo/customization",
            "/dashboard/businessinfo/subscription",
            "/dashboard/businessinfo/ownerdetails",
            "/dashboard/loyalty-program/how-to-redeem/voucher",
          ].includes(location.pathname) && (
            <div className="hidden sm:block">
              {location.pathname.includes("loyalty-program") ? (
                <LoyaltyProgramBanner />
              ) : userRole === "staff" ? (
                <StatisticsBanner />
              ) : (
                <AppointmentBanner />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
