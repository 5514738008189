import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export interface Customer {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  uid: string;
  code: string;
  avatarUrl: string | null;
  phoneNumber: string;
  createdAt: string;
  updatedAt: string;
}

export interface CRMData {
  customer: Customer;
  lastVisit: string;
  averageSpend: string;
  appointmentCount: number;
}

export interface GetCustomersResponse {
  status: number;
  message: "Clients CRM data retrieved successsfully.";
  data: CRMData[];
}

export const getClients = createAsyncThunk(
  "crm/getClients",
  async (_, thunkAPI) => {
    try {
      const {
        data: { data },
      } = await axios.get<GetCustomersResponse>(`/api/crm/client`);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
