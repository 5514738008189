import React, { useState, useEffect } from "react";
import PdfPreviewTemplate1 from "../../assets/images/pdftemplate1.png";
import moment from "moment";
import { useAppSelector } from "../../store/hooks";
import { selectServices } from "../../features/pages/pages";
import useCurrency from "../../hooks/useCurrency";

const presetColors = [
  "bg-gray-200",
  "bg-blue-200",
  "bg-purple-200",
  "bg-red-200",
  "bg-orange-200",
  "bg-yellow-200",
];

export interface ServiceQuoteFormI {
  reference?: string;
  service: number | string;
  others?: boolean;
  clientName: string;
  clientLastName: string;
  clientEmail: string;
  clientPhone: string;
  calendar: Date | string;
  staff?: number;
  budget?: number | string;
  requestedDate?: string;
  requestedTime?: string;
  timeSlot: string;
  price: number;
  applyDiscount?: boolean;
  discount: number;
  applyTaxRate?: boolean;
  taxRate: number;
  notes?: string;
  paymentDescription?: string;
}

function QuotePreview(props: ServiceQuoteFormI) {
  const [highlightColor, setHighlightColor] = useState(presetColors[0]);
  const [uploadedLogo] = useState<string | null>(null);
  const [invoiceNumber, setInvoiceNumber] = useState(props.reference);
  const services = useAppSelector(selectServices);
  const [service, setService] = useState(
    services.find((item) => item.id === props.service)
  );

  useEffect(() => {
    // Generate a random five-digit invoice number on mount
    if (!invoiceNumber) {
      setInvoiceNumber(`#${Math.floor(10000 + Math.random() * 90000)}`);
    }
  }, []);

  useEffect(() => {
    setService(services.find((item) => item.id === Number(props.service)));
  }, [props.service]);

  // Convert tax and discount to numbers
  const subtotal = parseFloat((props.price ?? 0).toString());
  const taxRate = props.taxRate ? parseFloat(props.taxRate.toString()) : 0;
  const discount = props.discount ? parseFloat(props.discount.toString()) : 0;
  const [selectedTemplate, setSelectedTemplate] = useState(0);

  // Calculate total price
  const taxAmount = (subtotal * taxRate) / 100;
  const discountAmount = (subtotal * props.discount) / 100;
  const total = subtotal + taxAmount - discountAmount;

  // Handle logo upload
  // const handleLogoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.files && event.target.files.length > 0) {
  //     const file = event.target.files[0];
  //     setUploadedLogo(URL.createObjectURL(file));
  //   }
  // };

  // List of invoice templates
  const invoiceTemplates = [{ src: PdfPreviewTemplate1, alt: "Template 1" }];

  return (
    <div className="w-full flex flex-col gap-6 font-outfit mb-4">
      <span className="text-2xl font-bold">Preview</span>

      {/* Invoice Preview */}
      <div className="p-6 w-full border rounded-2xl shadow-lg bg-white">
        <div
          className={`rounded-2xl p-6 flex justify-between ${highlightColor}`}
        >
          <div className="flex flex-col gap-2 text-sm">
            <span className="text-2xl mb-2 font-bold">Invoice</span>
            <span className="text-gray-700">Billed To:</span>
            <span className="font-semibold text-lg">
              {props.clientName} {props.clientLastName}
            </span>
            <span className="text-gray-700">Address / Contact Info</span>
            {props.clientEmail && (
              <span className="font-semibold text-lg">{props.clientEmail}</span>
            )}
            {props.clientPhone && (
              <span className="font-semibold text-lg">{props.clientPhone}</span>
            )}
          </div>
          <div className="flex flex-col gap-2 text-sm">
            <span className="text-gray-700">Invoice No.</span>
            <span className="text-lg font-bold">{invoiceNumber}</span>
            <span className="text-gray-700">Issued on</span>
            <span className="font-semibold">
              {moment().format("MMMM D, YYYY")}
            </span>
            <span className="text-gray-700">Payment Due</span>
            <span className="font-semibold">
              {moment(props.calendar).format("MMMM D, YYYY")}
            </span>
          </div>
        </div>

        {/* Description Section with Headers */}
        <div className="mt-6 mb-9">
          <div className="flex justify-between text-sm font-semibold pb-2 mt-2">
            <span className="font-bold">Description</span>
            <span>Price</span>
            <span>Total</span>
          </div>
          {service && (
            <div className="flex justify-between text-sm py-2 mb-9">
              <span>{service.label}</span>
              <span>{useCurrency(props.price ?? 0)}</span>
              <span>{useCurrency(props.price ?? 0)}</span>
            </div>
          )}
        </div>

        {/* Subtotal, Tax, Discount, and Balance Due */}
        <div className="flex justify-end mt-12">
          {" "}
          {/* Increased top margin */}
          <div className="text-right space-y-5">
            {" "}
            {/* Subtotal */}
            <div className="flex justify-between text-gray-700 text-sm">
              <span>Sub Total</span>
              <span className="font-bold text-black ml-8">
                {useCurrency(props.price ?? 0)}
              </span>
            </div>
            {/* Tax Rate */}
            {props.applyTaxRate && (
              <div className="flex justify-between text-gray-700 text-sm">
                <span>Tax Rate ({taxRate}%)</span>
                <span className="font-bold text-black ml-8">
                  {taxAmount.toFixed(2)}
                </span>
              </div>
            )}
            {/* Discount */}
            {props.applyDiscount && (
              <div className="flex justify-between text-gray-700 text-sm">
                <span>Discount ({discount}%)</span>
                <span className="font-bold text-black ml-8">
                  {discountAmount.toFixed(2)}
                </span>
              </div>
            )}
            {/* Balance Due Section */}
            <div className="relative w-full mt-6 ">
              <div
                className={`absolute right-[20px] top-[20px] shadow-md flex items-center px-4 py-2 rounded-full w-[250px] justify-between ${highlightColor}`}
              >
                <span className="text-gray-600 flex ">Balance Due</span>
                <span className="ml-2 font-bold text-xl text-black">
                  ${total.toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Business Details */}
        <div className="mt-12 border-t pt-12 flex flex-col w-full">
          {/* Logo - Show Only if Uploaded */}
          {uploadedLogo && (
            <div className="mb-4 self-start">
              <img
                src={uploadedLogo}
                alt="Company Logo"
                className="h-20 w-20 object-contain mx-auto"
              />
            </div>
          )}

          {/* Business Information Section */}
          <div className="w-full flex justify-between items-start text-sm mt-4">
            {/* Left Column: Business Details */}
            <div className="w-1/3 text-left">
              <span className="block font-semibold text-lg">
                The Common Room
              </span>
              <span className="block text-black-600">
                Address / Contact Info
              </span>
              <span className="block font-semibold text-black-700">
                ID#1 Label
              </span>
              <span className="block text-black-600">1234567890-123</span>
              <span className="block font-semibold text-black-700">
                ID#2 Label
              </span>
              <span className="block text-black-600">ABC-0987654321</span>
            </div>

            {/* Middle Column: Payment Instructions */}
            <div className="w-1/3 text-left">
              <span className="block font-semibold">Payment Instructions</span>
              <span className="block text-black-600">
                {props.paymentDescription
                  ? props.paymentDescription
                  : "No payment Instruction added"}
              </span>
            </div>

            {/* Right Column: Additional Notes */}
            <div className="w-1/3 text-left">
              <span className="block font-semibold">Additional Notes</span>
              <span className="block text-black-600">
                {props.notes ? props.notes : "No additional notes added"}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Invoice Customization */}
      <div className="p-6 border rounded-2xl shadow-lg bg-white w-full">
        <span className="text-lg font-bold">Customise Invoice Template</span>
        {/* Logo Upload and Highlight Colors */}
        <div className="mt-6 flex justify-between items-center">
          {/* Logo Upload Section */}

          {/* <div className="flex flex-col items-center">
            <span className="text-sm text-gray-600 font-bold">Add Logo</span>

            {uploadedLogo ? (
              <div className="mb-4 self-start">
                <img
                  src={uploadedLogo}
                  alt="Company Logo"
                  className="h-20 w-20 object-contain mx-auto"
                />
                <button
                  className="text-xs text-red-500 mt-1 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering file input
                    setUploadedLogo(null);
                  }}
                >
                  Remove Logo
                </button>
              </div>
            ) : (
              <label htmlFor="file-upload" className="cursor-pointer">
                <div className="h-20 w-20 flex items-center justify-center border rounded-md bg-gray-100 mt-2">
                  <span className="text-gray-500 text-lg">+</span>
                </div>
              </label>
            )}

            <input
              id="file-upload"
              type="file"
              className="hidden"
              onChange={handleLogoUpload}
            />
          </div> */}
          {/* Highlight Color Selection */}
          <div className="flex flex-col">
            <span className="text-sm text-gray-600 font-bold">
              Highlight Color
            </span>
            <div className="grid grid-cols-6 gap-2 mt-2">
              {presetColors.map((colorCode) => (
                <div
                  key={colorCode}
                  className={`w-6 h-6 ${colorCode} rounded-md cursor-pointer ${
                    colorCode === highlightColor ? "border-2 border-black" : ""
                  }`}
                  onClick={() => setHighlightColor(colorCode)}
                />
              ))}
            </div>
          </div>
        </div>
        {/* Invoice Templates Selection */}
        <div className="mt-4">
          <span className="text-sm text-gray-600 font-bold">
            Select Invoice Template
          </span>
          <div className="grid grid-cols-3 gap-4 mt-2">
            {invoiceTemplates.map((template, index) => (
              <img
                key={index}
                src={template.src}
                alt={template.alt}
                className={`border rounded-md cursor-pointer ${
                  selectedTemplate === index
                    ? "border-2 border-blue-500 shadow-lg"
                    : ""
                }`}
                onClick={() => setSelectedTemplate(index)}
                width={100}
                height={50}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuotePreview;
