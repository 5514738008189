import { useEffect, useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Invoice from "../../components/ServiceQuote/Invoice";
import Logs from "../../components/ServiceQuote/Logs";
import {
  fetchQuote,
  finalizeQuote,
  Quote,
  QuoteStatus,
} from "../../features/quote/service";
import axios from "axios";
import ModalContainer from "../../components/Modals/ModalContainer";
import { Icon } from "@iconify/react";
import { Button } from "../../components/Base/Button";
import Spinner from "../../components/Base/Spinner";

function QuoteDetails() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  // ✅ Define state with proper type safety
  const [quote, setQuote] = useState<Quote | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [canFinalize, setCanFinalize] = useState(false);
  const [isFinalized, setIsFinalized] = useState(false);
  const [isFinalizing, setIsFinalizing] = useState(false);

  // ✅ Memoize quoteId to prevent unnecessary re-renders
  const quoteId = useMemo(() => (id ? parseInt(id, 10) : null), [id]);

  const getQuote = async (id: number) => {
    try {
      const { data } = await fetchQuote(id);
      setQuote(data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError((error.response?.data as any).message ?? error.message);
      }
      console.error("Failed to fetch invoice:", error);
      navigate(`/dashboard/service-quote`);
    } finally {
      setIsLoading(false);
    }
  };

  const approveQuote = async (id: number, approve: boolean) => {
    try {
      setIsFinalizing(true);
      await finalizeQuote(id, approve);
      setIsFinalized(true);
      getQuote(id);
    } catch (error) {
      // if (axios.isAxiosError(error)) {
      //   setError((error.response?.data as any).message ?? error.message);
      // }
      // console.error("Failed to fetch invoice:", error);
      alert("Sorry, Couldn't finalize quote");
    } finally {
      setIsFinalizing(false);
    }
  };

  useEffect(() => {
    if (quoteId) {
      getQuote(quoteId);
    }
  }, []);
  useEffect(() => {
    if (quote) {
      if (QuoteStatus.Finalized !== quote.status) {
        setCanFinalize(false);
      } else {
        setCanFinalize(true);
      }
    }
  }, [quote]);

  // ✅ Show loading skeleton while fetching data
  if (isLoading) {
    return (
      <div className="w-full min-h-screen flex items-center justify-center">
        <p className="text-gray-500 text-lg">Loading Quote Details...</p>
      </div>
    );
  }

  // ✅ Show error if quote is not found
  if (error ?? !quote) {
    return (
      <div className="w-full min-h-screen flex items-center justify-center">
        <p className="text-red-500 text-lg">{error ?? "Quote not found."}</p>
      </div>
    );
  }

  return (
    <div className="w-full min-h-screen px-8 md:px-16 lg:px-24 py-8 bg-white text-black font-sans flex flex-col gap-6">
      {/* Finalize Modal Message */}
      <ModalContainer
        modalOpen={isFinalized}
        closeModal={() => {
          setIsFinalized(false);
        }}
        size="small"
      >
        <div className="flex flex-col items-center justify-center p-6 gap-4">
          <Icon
            icon="mdi:check-circle-outline"
            width={80}
            className="text-green-500"
          />
          <p className="text-center text-lg font-medium">
            Quote has been finalized successfully.
          </p>
          <Button onClick={() => navigate("/dashboard/appointments")}>
            GO TO APPOINTMENTS
          </Button>
        </div>
      </ModalContainer>
      {/* ✅ Header Section */}
      <div className="w-full flex flex-col gap-2">
        <span className="text-2xl font-semibold">Invoices</span>

        {/* ✅ Status and Reference ID */}
        <div className="flex items-center gap-3">
          <p className="font-inter uppercase text-gray-600 text-lg">
            #{quote.reference}
          </p>
          <div className="px-3 py-1 bg-gray-400 text-white rounded-lg text-sm font-bold uppercase">
            {quote.status}
          </div>
        </div>

        {/* ✅ Buttons */}
        <div className="flex justify-between mt-2">
          <div className="flex gap-4">
            <button
              disabled
              className="disabled:cursor-not-allowed text-black text-sm px-4 py-2 rounded-md font-bold border border-gray-400 bg-white "
            >
              EXPORT
            </button>
            <button
              className="bg-white text-black text-sm px-4 py-2 rounded-md font-bold border border-gray-400"
              onClick={() =>
                navigate(`/dashboard/service-quote/edit/${quoteId}`)
              }
            >
              EDIT INVOICE
            </button>
          </div>
          <button
            disabled={!canFinalize}
            className="bg-green-400 text-white text-sm px-4 py-2 rounded-md font-bold border border-white flex justify-center items-center text-center disabled:bg-gray-400 disabled:cursor-not-allowed"
            onClick={() => {
              if (quoteId) approveQuote(quoteId, true);
            }}
          >
            {isFinalizing ? <Spinner /> : "FINALIZE"}
          </button>
        </div>
      </div>

      {/* ✅ Invoice and Logs Section */}
      <div className="flex flex-col md:flex-row gap-6 w-full">
        <div className="md:w-2/3 w-full">
          <Invoice quote={quote} />
        </div>
        <div className="md:w-1/3 w-full">
          <Logs quote={quote} />
        </div>
      </div>
    </div>
  );
}

export default QuoteDetails;
