/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unreachable */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { InputValidationError } from "../InputValidationError";
import { Button } from "../Base/Button";
import { Input } from "../Base/Input";
import { RadioInput } from "../Base/RadioInput";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import { Textarea } from "../Base/Textarea";
import Tabs from "../ServiceTabs/Tabs";
import ModalContainer from "./ModalContainer";
import useFileUpload from "../../hooks/useFileUpload";
import Spinner from "../Base/Spinner";
import axios from "axios";
import { GalleryType } from "../../interfaces/gallery";
import { InputDecimal } from "../Base/InputDecimal";
import { getBusinessCurrency } from "../../features/business/business";
import { useAppSelector } from "../../store/hooks";

interface Props {
  closeModal: any;
  modalOpen: boolean;
  isEdit?: boolean;
  service?: any;
  saveService?: any;
  deleteAddons?: any;
  refresh?: any;
  isUpdating?: boolean;
}

const AddServiceModal = (props: Props) => {
  const { submitFile } = useFileUpload();
  const inputFileRef = React.useRef<any>(null);
  const [selectedImage, setSelectedImage] = useState<any>();
  const [submittingFile, setSubmittingFile] = useState(false);
  const [addOn, setAddOn] = useState<any>([]);
  const [newAddons, setnewAddons] = useState<any>([]);
  const [newCustomisations, setNewCustomisations] = useState<any>([]);
  const [galleryModal, setGalleryModal] = useState(false);
  const [images, setImages] = useState<GalleryType[]>([]);
  const [refreshPage, setRefreshPage] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked1, setIsChecked1] = useState(false);
  const [isLive, setIsLive] = useState(false);
  const currency = useAppSelector((state) => getBusinessCurrency(state));
  const [selectedGalleryImages, setSelectedGalleryImages] = useState<
    GalleryType[]
  >([]);
  const [showSelectedGalleryImages, setShowSelectedGalleryImages] = useState<
    GalleryType[]
  >([]);

  const [submittingService, setSubmittingService] = useState(false);
  const addImage = async (event: any) => {
    setSubmittingFile(true);
    const Image = event.target.files[0];
    const imageUrl = await submitFile(Image, "service");
    if (imageUrl) setSelectedImage(imageUrl);
    setSubmittingFile(false);
  };

  const schema = yup.object().shape({
    name: yup.string().required("Please enter the name of your service"),
    price: yup
      .string()
      .min(1, `Price must be at least ${currency} 1`)
      .required("Please set a price for your service"),
    description: yup.string(),
    duration: yup.string().required("Please enter a duration for your service"),
  });
  const saveService = async (type: string, service: any) => {
    try {
      setSubmittingService(true);
      const payload = { ...service };
      await axios.post(`/api/business/service`, payload);
      props.closeModal();
    } catch (error: any) {
      console.error("error", error.response.data.errors);
    } finally {
      setSubmittingService(false);
    }
  };
  const addService = async (form: any) => {
    const finalCustomization = newCustomisations.filter(
      (item: { name: string }) => item.name !== ""
    );
    const finalAddons = newAddons.filter(
      (item: { name: string }) => item.name !== ""
    );
    const images = showSelectedGalleryImages.map((item) => ({
      galleryId: item.id,
    }));

    const data = {
      name: form.name,
      image: selectedImage,
      price: Number(form.price).toFixed(2),
      duration: form.duration,
      description: form.description,
      isLive,
      isHome: isChecked,
      selectStaff: isChecked1,
      addons: finalAddons.length > 0 ? finalAddons : [],
      customisations: finalCustomization.length > 0 ? finalCustomization : [],
      images,
    };
    if (props.saveService) {
      await props.saveService(
        `${props.isEdit ? `${props.service.id}` : "Save"}`,
        data
      );
    } else {
      await saveService("Save", data);
    }
  };
  interface sum {
    updatedAt?: string;
    createdAt: string;
    id: number;
    name: string;
    serviceId: number;
    price: string;
  }
  interface customise {
    name: string;
    option: boolean;
    value: string[];
    id: number;
  }
  const handleAddData = (array: sum[]) => {
    const formated = array.map(
      ({ updatedAt, createdAt, id, serviceId, price, name }) => {
        return { name, price: parseInt(price), id };
      }
    );

    setnewAddons(formated);
  };
  const handleAddCustomisation = (array: customise[]) => {
    const formated = array.map(({ option, value, name, id }) => {
      return { name, option, value, id };
    });

    setNewCustomisations(formated);
  };

  useEffect(() => {
    if (props.isEdit) {
      setSelectedImage(props.service.image);
      setIsChecked(props.service?.isHome);
      setIsChecked1(props.service?.selectStaff);
      setIsLive(props.service?.isLive);
    }
    getAllImages();
    props.refresh && props.refresh();
  }, [refreshPage]);
  useEffect(() => {
    if (props.service?.images.length) {
      const formattedImages = props.service.images.map((item: any) => ({
        ...item.gallery,
      }));
      setShowSelectedGalleryImages(formattedImages);
    }
  }, [props.service]);
  const getAllImages = async () => {
    try {
      const { data } = await axios.get(`/api/gallery`);
      setImages(data.data);
    } catch (e) {
      console.error(e);
    }
  };

  const onChange = async (event: any) => {
    try {
      setSubmittingFile(true);
      const file = event.target.files[0];
      const imageUrl = await submitFile(file);
      if (imageUrl) {
        sendFile(imageUrl);
      }
    } catch (e) {
      setSubmittingFile(false);
      console.error(e);
    } finally {
      setSubmittingFile(false);
    }
  };
  const sendFile = async (image: string) => {
    try {
      await axios.post(`/api/gallery`, {
        file: image,
      });
      setSubmittingFile(false);
      setRefreshPage((previous) => !previous);
    } catch (e) {
      setSubmittingFile(false);
      console.error(e);
    }
  };
  useEffect(() => {}, [images, selectedGalleryImages]);

  return (
    <ModalContainer
      modalOpen={props.modalOpen}
      closeModal={props.closeModal}
      size="service"
    >
      <Formik
        initialValues={{
          name: props.service ? props.service.name : "",
          price: props.service ? props.service.price : "",
          description: props.service ? props.service.description : "",
          duration: props.service ? props.service.duration : "",
          addons: [],
          customisations: [],
        }}
        onSubmit={(data, { setSubmitting }) => {
          setSubmitting(true);
          (async () => {
            addService(data);
          })();
        }}
        validationSchema={schema}
      >
        {({ isSubmitting, errors, touched, values }) => (
          <Form>
            <div className="p-6 bg-[#f1f1f1] rounded-lg mr-3 sm:mr-0 flex items-center justify-center sm:justify-start">
              <div className="w-full space-y-4">
                <div>
                  <Field
                    name="name"
                    as={Input}
                    label="Service Name"
                    placeholder="Service Name"
                  />

                  {errors.name && touched.name && (
                    <InputValidationError text={errors.name} />
                  )}
                </div>
                <div className="flex justify-between space-x-4">
                  <div className="w-[47%]">
                    <Field
                      name="price"
                      as={InputDecimal}
                      label={`Service Price (${currency})`}
                      type="number"
                      min={1}
                    />
                    {errors.price && touched.price && (
                      <InputValidationError text={errors.price} />
                    )}
                  </div>
                  <div className="w-[44%]">
                    <Field
                      name="duration"
                      as={Input}
                      label="Service Duration (minutes)"
                      placeholder="60"
                      type="number"
                    />

                    {errors.duration && touched.duration && (
                      <InputValidationError text={errors.duration} />
                    )}
                  </div>
                </div>

                <div>
                  <Field
                    name="description"
                    as={Textarea}
                    label="Service Description"
                  />

                  {errors.description && touched.description && (
                    <InputValidationError text={errors.description} />
                  )}
                </div>

                <div className="py-5">
                  <Tabs
                    service={props.service}
                    setValue={(e: any) => {
                      setAddOn([...addOn, e]);
                    }}
                    isEdit={props.isEdit}
                    addData={handleAddData}
                    addCustomisation={handleAddCustomisation}
                  />

                  <div className="grid grid-cols-6 gap-4 pt-8 pb-3">
                    {showSelectedGalleryImages.map(
                      (selected: GalleryType, selectedIndex: number) => (
                        <div
                          key={selectedIndex}
                          className="flex items-center justify-center border-2 rounded-lg w-[4.5rem] h-[4.5rem]"
                        >
                          <div className="relative">
                            <>
                              <img
                                src={selected.file}
                                alt=""
                                className="object-cover w-[4.5rem] h-[4.5rem] rounded"
                              />
                              <span
                                className="absolute cursor-pointer -right-2 -top-2 text-danger"
                                onClick={async () => {
                                  if (props.isEdit) {
                                    try {
                                      await axios.delete(
                                        `/api/gallery/service`,
                                        {
                                          data: {
                                            serviceId: props.service.id,
                                            galleryIds: [selected.id],
                                          },
                                        }
                                      );
                                      setRefreshPage((previous) => !previous);
                                    } catch (e) {
                                      console.error(e);
                                    }
                                  }
                                  setShowSelectedGalleryImages((current) =>
                                    current.filter(
                                      (item) => item.id !== selected.id
                                    )
                                  );
                                }}
                              >
                                <Icon icon="akar-icons:circle-minus-fill" />
                              </span>
                            </>
                          </div>
                        </div>
                      )
                    )}

                    {!selectedImage && (
                      <div
                        className="flex flex-col items-center text-white justify-center w-[4.5rem] h-[4.5rem] p-3 rounded cursor-pointer bg-main"
                        onClick={() => {
                          setGalleryModal(true);
                          // inputFileRef.current.click()
                        }}
                      >
                        <Icon icon="akar-icons:plus" color="white" width={25} />
                        <span className="text-[9px]">Add Photo</span>
                      </div>
                    )}
                    <input
                      type="file"
                      name="image"
                      multiple
                      hidden
                      ref={inputFileRef}
                      onChange={(event) => {
                        // addImage(event);
                        onChange(event);
                      }}
                    />
                  </div>

                  <div className="">
                    <div className="flex items-center justify-between mt-1">
                      <span className="font-[600] text-sm">
                        List on marketplace
                      </span>
                      <div>
                        <Field
                          name="isLive"
                          type="checkbox"
                          as={"input"}
                          id="serviceModalLiveToggle"
                          className="hidden"
                          // eslint-disable-next-line @typescript-eslint/no-misused-promises
                          onChange={(e: any) => {
                            setIsLive(e.target.checked);
                          }}
                          checked={isLive}
                        />
                        <div className="cursor-pointer checkbox">
                          <input
                            type="checkbox"
                            id="serviceModalLiveToggle"
                            className="hidden"
                            // eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onChange={(e) => {
                              setIsLive(e.target.checked);
                            }}
                            checked={isLive}
                          />
                          <label htmlFor="serviceModalLiveToggle" className="">
                            toggle
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-between mt-2">
                      <span className="font-[600] text-sm">
                        Is this a Home Service
                      </span>
                      <div>
                        <div className="cursor-pointer checkbox">
                          <input
                            type="checkbox"
                            id="serviceModal"
                            className="hidden"
                            // eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onChange={(e) => {
                              setIsChecked(e.target.checked);
                            }}
                            checked={isChecked}
                          />
                          <label htmlFor="serviceModal" className="">
                            toggle
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-between mt-2">
                      <span className="font-[600] text-sm">
                        Allow your clients select their preferred staff
                      </span>
                      <div>
                        <Field
                          name="isHome"
                          type="checkbox"
                          as={"input"}
                          id="serviceModal1"
                          className="hidden"
                          // eslint-disable-next-line @typescript-eslint/no-misused-promises
                          onChange={(e: any) => {
                            setIsChecked1(e.target.checked);
                          }}
                          checked={isChecked1}
                        />
                        <div className="cursor-pointer checkbox">
                          <input
                            type="checkbox"
                            id="serviceModal1"
                            className="hidden"
                            // eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onChange={(e) => {
                              setIsChecked1(e.target.checked);
                            }}
                            checked={isChecked1}
                          />
                          <label htmlFor="serviceModal1" className="">
                            toggle
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex mt-6">
              <Button
                isSubmitting={submittingService || props.isUpdating}
                disabled={submittingService || props.isUpdating}
                type="submit"
              >
                {props.isEdit ? "Update" : "Save"}
              </Button>
              <Button
                variant="bg-danger ml-4"
                onClick={() => {
                  props.closeModal();
                }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <ModalContainer
        modalOpen={galleryModal}
        closeModal={() => {
          setGalleryModal(false);
        }}
        size="large"
      >
        <div>
          <p className="font-[600] interFamily mb-3">
            Add images from your gallery to your service
          </p>
          <div className="grid grid-cols-6 gap-4">
            {images.map((image: GalleryType, index) => (
              <div
                key={index}
                className="w-28 h-28 cursor-pointer relative"
                onClick={() => {
                  const IsImageAlreadySelected =
                    selectedGalleryImages.includes(image);
                  if (IsImageAlreadySelected) {
                    setSelectedGalleryImages((current) =>
                      current.filter((item) => item.id !== image.id)
                    );
                  } else {
                    setSelectedGalleryImages([...selectedGalleryImages, image]);
                  }
                }}
              >
                <img
                  src={image.file}
                  className="object-cover w-full h-full rounded-xl"
                />
                <div
                  className={
                    (selectedGalleryImages.includes(image)
                      ? " bg-main "
                      : " bg-white ") +
                    " h-5 w-5 rounded-full absolute top-3 right-3 border-2 border-black"
                  }
                >
                  {" "}
                </div>
              </div>
            ))}

            <button
              className="flex items-center justify-center w-28 h-28 p-3 rounded  bg-main"
              onClick={() => {
                // setGalleryModal(true)
                inputFileRef.current.click();
              }}
              disabled={submittingFile}
            >
              <div className="flex flex-col items-center justify-center font-bold">
                <Icon icon="akar-icons:plus" color="white" width="30" />
                <span className="text-white text-xs">
                  {submittingFile ? "Loading ..." : "Add Photo"}
                </span>
              </div>
            </button>
          </div>
          <div className="flex justify-end mt-5">
            <Button
              onClick={async () => {
                if (props.isEdit) {
                  const imageIds = selectedGalleryImages.map((item) => item.id);
                  try {
                    await axios.post(`/api/gallery/service`, {
                      serviceId: props.service.id,
                      galleryIds: imageIds,
                    });
                    setRefreshPage((previous) => !previous);
                  } catch (e) {
                    console.error(e);
                  }
                  setGalleryModal(false);
                  setShowSelectedGalleryImages([
                    ...showSelectedGalleryImages,
                    ...selectedGalleryImages,
                  ]);
                  setSelectedGalleryImages([]);
                } else {
                  setGalleryModal(false);
                  setShowSelectedGalleryImages(selectedGalleryImages);
                  setSelectedGalleryImages([]);
                }
              }}
            >
              {" "}
              {props.isEdit ? "Update" : "Save"}
            </Button>
          </div>
        </div>
      </ModalContainer>
    </ModalContainer>
  );
};

export default AddServiceModal;
