import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import authSlice from "../features/auth/auth";
import loaderSlice from "../features/loader/loader";
import businessSlice from "../features/business/business";
import appointmentSlice from "../features/appointment/appointment";
import paymentSlice from "../features/payments/payments";
import tourSlice from "../features/tour/tour";
import pageSlice from "../features/pages/pages";
import loyaltyProgramSlice from "../features/loyalty/loyalty";
import crmReducer from "../features/customer/reducer";
import staffReducer from "../features/staff/reducer";

const persistConfig = {
  key: "eddy",
  storage,
};
const appReducer = combineReducers({
  loader: loaderSlice,
  auth: authSlice,
  business: businessSlice,
  appointment: appointmentSlice,
  payments: paymentSlice,
  tour: tourSlice,
  pages: pageSlice,
  loyalty: loyaltyProgramSlice,
  crm: crmReducer,
  staff: staffReducer,
});
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const rootReducer = (state: any, action: any) => {
  if (action.type === "auth/LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,

  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  //   middleware: [thunk]
});

export const persistor = persistStore(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
