import React from "react";
import { Quote } from "../../features/quote/service";
import moment from "moment";
import useCurrency from "../../hooks/useCurrency";

interface Props {
  quote: Quote;
}

function Invoice({ quote }: Props) {
  const {
    customer,
    date,
    services,
    taxRate,
    discount,
    reference,
    merchant,
    paymentDescription,
    notes,
    price,
  } = quote;

  const servicePrice =
    Number(price) ||
    services.reduce((acc, service) => {
      acc += service.service?.price ?? 0;
      return acc;
    }, 0);
  const serviceTaxRate = taxRate || 0;
  const serviceDiscount = discount || 0;

  const subtotal = servicePrice;
  const taxAmount = (subtotal * serviceTaxRate) / 100;
  const discountAmount = (subtotal * serviceDiscount) / 100;
  const balanceDue = subtotal + taxAmount - discountAmount;

  return (
    <div className="p-6 w-full border rounded-2xl shadow-lg bg-white">
      {/* Header Section */}
      <div className="rounded-2xl p-6 flex justify-between bg-gray-100">
        <div className="flex flex-col gap-2 text-sm">
          <span className="text-2xl mb-2 font-bold">Invoice</span>
          <span className="text-gray-700">Billed To:</span>
          <span className="font-semibold text-lg">{customer.fullName}</span>
          <span className="text-gray-700">Address / Contact Info</span>
        </div>
        <div className="flex flex-col gap-2 text-sm justify-end text-right">
          <span className="text-gray-700">Invoice No.</span>
          <span className="text-lg font-bold">#{reference}</span>
          <span className="text-gray-700">Issued on</span>
          <span className="font-semibold">
            {moment(date).format("MMMM D, YYYY")}
          </span>
          <span className="text-gray-700">Payment Due</span>
          <span className="font-semibold">
            {moment(date).format("MMMM D, YYYY")}
          </span>
        </div>
      </div>

      {/* Service Breakdown */}
      <div className="mt-6 mb-9">
        <table className="w-full border-collapse">
          {/* Table Header */}
          <thead>
            <tr className="text-sm font-semibold ">
              <th className="text-left py-2">Description</th>
              <th className="text-right py-2">Price</th>
              <th className="text-right py-2">Total</th>
            </tr>
          </thead>

          {/* Table Body */}
          <tbody>
            {services.map((service, index) => (
              <tr key={index} className="text-sm ">
                <td className="py-2">
                  {service.service?.label ?? service.description}
                </td>
                <td className="py-2 text-right">
                  {useCurrency(price ?? service.service?.price ?? 0)}
                </td>
                <td className="py-2 text-right">
                  {useCurrency(price ?? service.service?.price ?? 0)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Subtotal, Tax, Discount, and Balance Due */}
      <div className="flex justify-end mt-12">
        <div className="text-right space-y-5">
          <div className="flex justify-between text-gray-700 text-sm">
            <span>Sub Total</span>
            <span className="font-bold text-black ml-8">
              ${subtotal.toFixed(2)}
            </span>
          </div>
          {taxRate > 0 && (
            <div className="flex justify-between text-gray-700 text-sm">
              <span>Tax Rate ({serviceTaxRate}%)</span>
              <span className="font-bold text-black ml-8">
                ${taxAmount.toFixed(2)}
              </span>
            </div>
          )}
          {discount > 0 && (
            <div className="flex justify-between text-gray-700 text-sm">
              <span>Discount</span>
              <span className="font-bold text-black ml-8">
                -${discountAmount.toFixed(2)}
              </span>
            </div>
          )}
          <div className="relative w-full mt-6">
            <div className="absolute right-[20px] top-[20px] shadow-md flex items-center px-4 py-2 rounded-full w-[250px] justify-between bg-gray-100">
              <span className="text-gray-600 flex">Balance Due</span>
              <span className="ml-2 font-bold text-xl text-black">
                ${balanceDue.toFixed(2)}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Business Details */}
      <div className="mt-12 border-t pt-12 flex flex-col w-full">
        <div className="w-full flex justify-between items-start text-sm mt-4">
          {/* Left Column: Merchant Details */}
          <div className="w-1/3 text-left">
            <span className="block font-semibold text-lg">
              {merchant?.name || "Merchant Name"}
            </span>
            <span className="block text-black-600">Address / Contact Info</span>
            <span className="block font-semibold text-black-700">
              ID#1 Label
            </span>
            <span className="block text-black-600">1234567890-123</span>
            <span className="block font-semibold text-black-700">
              ID#2 Label
            </span>
            <span className="block text-black-600">ABC-0987654321</span>
          </div>

          {/* Middle Column: Payment Instructions */}
          <div className="w-1/3 text-left">
            <span className="block font-semibold">Payment Instructions</span>
            <span className="block text-black-600">
              {paymentDescription || "No payment instructions added"}
            </span>
          </div>

          {/* Right Column: Additional Notes */}
          <div className="w-1/3 text-left">
            <span className="block font-semibold">Additional Notes</span>
            <span className="block text-black-600">
              {notes || "No additional notes added"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Invoice;
