import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import QuotePreview, {
  ServiceQuoteFormI,
} from "../../components/ServiceQuote/QuotePreview";
import { PageHeader } from "../../components/Base/PageHeader";
import CalendarModal from "../../components/Modals/Quote/CalendarModal";
import { Icon } from "@iconify/react";
import { selectServices } from "../../features/pages/pages";
import {
  fetchQuote,
  modifyQuote,
  ModifyServiceQuotePayload,
  Quote,
} from "../../features/quote/service";
import { getMyServices } from "../../features/service/service";
import { getStaffs } from "../../features/staff/service";
import AddServiceModal from "../../components/Modals/AddServiceModal";
import {
  GetAvailableSlotParams,
  getAvailableSlots,
} from "../../features/operations/service";
import Spinner from "../../components/Base/Spinner";
import useCurrency from "../../hooks/useCurrency";
import { isNumber } from "lodash";
import moment from "moment";
import { selectStaffs } from "../../features/staff/reducer";

function EditInvoice() {
  const { id } = useParams();
  const quoteId = id ? parseInt(id, 10) : null;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const formikRef = useRef<FormikProps<ServiceQuoteFormI>>(null);
  const [quote, setQuote] = useState<Quote>();
  const [initialValues, setInitialValues] = useState<ServiceQuoteFormI>();
  const [isLoading, setIsLoading] = useState(true);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [openServiceModal, setOpenServiceModal] = useState(false);
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);
  const [timeSlots, setTimeSlots] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const [loadingSlots, setLoadingSlots] = useState(false);

  const services = useAppSelector(selectServices);
  const staffs = useAppSelector(selectStaffs);

  const getQuote = async (id: number) => {
    try {
      const { data } = await fetchQuote(id);
      setQuote(data);
    } catch (error) {
      console.error("Failed to fetch invoice:", error);
      navigate(`/dashboard/service-quote/${quoteId}`);
    } finally {
      setIsLoading(false);
    }
  };
  // Fetch available time slots based on the selected date & Service
  const fetchTimeSlots = async (date: Date, serviceId: number | string) => {
    setLoadingSlots(true);
    try {
      const params: GetAvailableSlotParams = {
        serviceId,
        date: date.toISOString(),
      };
      const { data } = await getAvailableSlots(params);
      setTimeSlots(data.map((slot) => ({ value: slot, label: slot })));
    } catch (error) {
      alert("Failed to fetch time slots");
    } finally {
      setLoadingSlots(false);
    }
  };

  useEffect(() => {
    if (quoteId) {
      getQuote(quoteId);
    }
    dispatch(getMyServices());
    dispatch(getStaffs());
  }, []);

  useEffect(() => {
    if (quote) {
      const {
        services,
        customer: {
          firstName: clientName,
          lastName: clientLastName,
          email: clientEmail,
          phoneNumber: clientPhone,
        },
        budget,
        price,
        discount,
        taxRate,
        notes,
        paymentDescription,
        timeSlot,
        requestedDate,
        requestedTime,
      } = quote;
      const payload: ServiceQuoteFormI = {
        service: quote.services[0].serviceId || quote.services[0].description,
        others: services[0].others,
        clientName,
        clientEmail,
        clientLastName,
        clientPhone,
        budget: useCurrency(budget),
        price,
        discount,
        taxRate,
        notes,
        paymentDescription,
        calendar: new Date(quote.date),
        timeSlot,
        requestedDate,
        requestedTime,
        applyDiscount: Boolean(discount),
        applyTaxRate: Boolean(taxRate),
      };
      setInitialValues(payload);
      if (isNumber(services[0].serviceId)) {
        fetchTimeSlots(new Date(quote.date), services[0].serviceId);
      }
    }
  }, [quote]);

  if (isLoading)
    return (
      <div className="text-center mt-10 h-screen flex justify-center items-center">
        <Spinner />
      </div>
    );

  if (!quote)
    return <p className="text-center mt-10 text-red-500">Invoice not found.</p>;

  const handleQuoteEdit = async (
    values: ServiceQuoteFormI,
    formikHelpers: FormikHelpers<any>
  ) => {
    const { resetForm } = formikHelpers;
    const {
      service,
      calendar,
      price,
      timeSlot,
      discount,
      notes: note,
      taxRate,
      staff,
    } = values;
    try {
      const payload: ModifyServiceQuotePayload = {
        payload: {
          staffId: staff,
          timeSlot,
          date: calendar,
          notes: note,
          discount,
          taxRate,
          price,
        },
        services: [service],
      };
      await modifyQuote(quote.id, payload);
      resetForm();
      setIsSuccessPopupOpen(true);
    } catch (e) {
      alert("Failed to Modify quote");
    }
  };

  return (
    <div className="w-full max-h-screen flex justify-evenly gap-4 overflow-y-auto">
      {initialValues && (
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={handleQuoteEdit}
        >
          {({ values, isSubmitting, errors, touched }) => (
            <>
              <div className="w-full border rounded px-12 py-3 flex flex-col h-full">
                <PageHeader text="Edit Invoice" />
                <Form className="space-y-4 p-3 w-[70%]">
                  {/* Service Selection */}
                  <div>
                    <label className="block text-sm font-medium">
                      Service *
                    </label>
                    <div className="flex gap-4 w-full items-center">
                      <div className="w-full">
                        <Field
                          as={"select"}
                          name="service"
                          className="border rounded px-2 py-1 w-full"
                        >
                          {services.map((service) => (
                            <option key={service.id} value={Number(service.id)}>
                              {service.label}
                            </option>
                          ))}
                        </Field>
                        {errors.service && touched.service && (
                          <p className="text-red-500 text-sm">
                            {String(errors.service)}
                          </p>
                        )}
                      </div>
                      <Icon
                        className="cursor-pointer"
                        icon="material-symbols:add"
                        onClick={() => setOpenServiceModal(true)}
                      />
                    </div>
                  </div>

                  {/* Service Description - Show Only if the service selected isn't on the system */}
                  {initialValues.others && !Number(initialValues.service) && (
                    <div>
                      <label className="block text-sm font-medium">
                        Service Description *
                      </label>
                      <textarea
                        readOnly
                        value={initialValues.service}
                        rows={3}
                        className="border rounded px-2 w-full"
                      />
                      <p className="text-red-500 text-sm">
                        {"The service selected doesn't exist, please create"}
                      </p>
                    </div>
                  )}

                  {/* Client Selection */}
                  <div>
                    <label className="block text-sm font-medium">Client</label>
                    <input
                      type="text"
                      readOnly
                      value={values.clientName + " " + values.clientLastName}
                      disabled
                      className="disabled:cursor-not-allowed border rounded w-full bg-gray-100"
                    />
                  </div>

                  {/* Calendar Selection */}
                  <div className="relative">
                    <label className="block text-sm font-medium">
                      Calendar *
                    </label>
                    <Field
                      as="input"
                      placeholder="Select a date"
                      className="border rounded px-2 py-1 w-full"
                      name="calender"
                      onClick={() => setIsCalendarOpen(true)}
                      value={
                        values.calendar
                          ? (values.calendar as Date).toDateString()
                          : ""
                      }
                    />
                    {initialValues.requestedDate && (
                      <label className="block text-xs font-medium italic mt-2 text-green-800">
                        Requested Date -{" "}
                        {moment(initialValues.requestedDate).calendar()}
                      </label>
                    )}
                    {/* <input
                      type="text"
                      className="border rounded px-2 py-1 w-full"
                      value={selectedDate ? selectedDate.toDateString() : ""}
                      placeholder="Select a date"
                      readOnly
                      onClick={() => setIsCalendarOpen(true)}
                    /> */}
                    <CalendarModal
                      isOpen={isCalendarOpen}
                      onClose={() => setIsCalendarOpen(false)}
                      onDateSelect={(date) => {
                        formikRef.current?.setFieldValue("calendar", date);
                        if (values.service && date) {
                          fetchTimeSlots(date, values.service);
                        }
                      }}
                    />
                  </div>

                  {/* Staff */}
                  <div>
                    <label className="block text-sm font-medium">
                      Staff (Optional)
                    </label>
                    {!staffs.length ? (
                      <Spinner />
                    ) : (
                      <Field
                        as="select"
                        name="staff"
                        className="border rounded px-2 py-1 w-full"
                      >
                        {staffs.map((staff) => (
                          <option key={staff.id} value={staff.id}>
                            {staff.firstName + " " + staff.lastName}
                          </option>
                        ))}
                      </Field>
                    )}
                  </div>

                  {/* Time Slot Selection */}
                  <div>
                    <label className="block text-sm font-medium">
                      Time Slot
                    </label>
                    {loadingSlots ? (
                      <Spinner />
                    ) : (
                      <Field
                        name="timeSlot"
                        as="select"
                        className="border rounded px-2 py-1 w-full"
                      >
                        {timeSlots.map((t) => (
                          <option key={t.value} value={t.value}>
                            {t.label}
                          </option>
                        ))}
                      </Field>
                    )}

                    {errors.timeSlot && touched.timeSlot && (
                      <p className="text-red-500 text-sm">{errors.timeSlot}</p>
                    )}
                    {initialValues.requestedTime && (
                      <label className="block text-xs font-medium italic mt-2 text-green-800">
                        Requested Time - {initialValues.requestedTime}
                      </label>
                    )}
                  </div>

                  {/*  Budget & Price */}
                  <div className="flex gap-4">
                    {/* Budget */}
                    <div>
                      <label className="block text-sm font-medium">
                        Budget
                      </label>
                      <Field
                        type="input"
                        readOnly
                        disabled
                        name="budget"
                        className="disabled:cursor-not-allowed border rounded px-2 py-1 w-full bg-gray-100"
                      />
                    </div>
                    {/* Price */}
                    <div>
                      <label className="block text-sm font-medium">
                        Price *
                      </label>
                      <Field
                        type="number"
                        name="price"
                        className="border rounded px-2 py-1 w-full"
                      />
                      {services.length &&
                        services.find(
                          (_service) => _service.id === Number(values.service)
                        ) && (
                          <label className="block text-xs font-medium italic mt-2 text-green-800">
                            Suggested Price -{" "}
                            {useCurrency(
                              services.find(
                                (_service) =>
                                  _service.id === Number(values.service)
                              )?.price ?? 0
                            )}
                          </label>
                        )}
                    </div>
                  </div>

                  {/* Apply Discount */}
                  <div>
                    <label className="block text-sm font-medium">
                      <Field type="checkbox" name="applyDiscount" />
                      <span className="mx-2">Apply Discount</span>
                    </label>
                    {values.applyDiscount && (
                      <Field
                        type="text"
                        name="discount"
                        className="border rounded px-2 py-1 w-full mt-2"
                        placeholder="Enter discount"
                      />
                    )}
                    {errors.discount && touched.discount && (
                      <p className="text-red-500 text-sm">{errors.discount}</p>
                    )}
                  </div>

                  {/* Apply Tax Rate */}
                  <div>
                    <label className="block text-sm font-medium">
                      <Field type="checkbox" name="applyTaxRate" />
                      <span className="mx-2">Apply Tax Rate</span>
                    </label>
                    {values.applyTaxRate && (
                      <Field
                        type="text"
                        name="taxRate"
                        className="border rounded px-2 py-1 w-full mt-2"
                        placeholder="Enter tax rate"
                      />
                    )}
                    {errors.taxRate && touched.taxRate && (
                      <p className="text-red-500 text-sm">{errors.taxRate}</p>
                    )}
                  </div>

                  {/* Payment Instructions */}
                  <div>
                    <label className="block text-sm font-medium">
                      Payment Instruction
                    </label>
                    <Field
                      as="textarea"
                      name="paymentDescription"
                      className="border rounded px-2 py-1 w-full"
                    />
                  </div>

                  {/* Notes */}
                  <div>
                    <label className="block text-sm font-medium">Notes</label>
                    <Field
                      as="textarea"
                      name="notes"
                      className="border rounded px-2 py-1 w-full"
                    />
                  </div>

                  {/* Buttons */}
                  <div className="flex gap-4 mt-6">
                    <button
                      type="button"
                      className="px-4 py-2 bg-red-600 text-white rounded-md"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="px-6 py-2 bg-green-600 text-white rounded-md"
                    >
                      {isSubmitting ? <Spinner /> : "Save Changes"}
                    </button>
                  </div>
                </Form>
              </div>
              <QuotePreview {...values} />
            </>
          )}
        </Formik>
      )}
      <AddServiceModal
        modalOpen={openServiceModal}
        closeModal={() => {
          dispatch(getMyServices());
          setOpenServiceModal(false);
        }}
      />

      {/* Success Popup */}
      {isSuccessPopupOpen && (
        <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50">
          <div className="bg-white p-10 w-[400px] rounded-xl shadow-lg flex flex-col items-center gap-4">
            <Icon
              icon="mdi:check-circle-outline"
              className="text-green-500 text-6xl"
            />
            <p className="text-black text-lg font-semibold">
              Invoice updated successfully.
            </p>
            <button
              className="bg-black text-white px-6 py-3 rounded-md font-semibold"
              onClick={() => navigate(`/dashboard/service-quote/${quoteId}`)}
            >
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditInvoice;
