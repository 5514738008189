import { createSlice } from "@reduxjs/toolkit";
import { getStaffs, Staff } from "./service";
import { RootState } from "../../store/store";

interface InitialState {
  staffs: Staff[];
  isFetching: boolean;
}

const initialState: InitialState = {
  staffs: [],
  isFetching: false,
};

export const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStaffs.fulfilled, (state, action) => {
      state.staffs = action.payload;
      state.isFetching = false;
    });
    builder.addCase(getStaffs.rejected, (state, action) => {
      state.isFetching = false;
    });
    builder.addCase(getStaffs.pending, (state, action) => {
      state.isFetching = true;
    });
  },
});

// Other code such as selectors can use the imported `RootState` type
export const selectStaffs = (state: RootState) => state.staff.staffs;
export const selectStaffLoadingState = (state: RootState) =>
  state.staff.isFetching;

export default staffSlice.reducer;
