/* eslint-disable react/no-children-prop */
import Login from "../views/Auth/Login";
import Register from "../views/Auth/Register";
import RegisterUser from "../views/Auth/RegisterUser";
import ForgotPassword from "../views/Auth/ForgotPassword";
import ChangePassword from "../views/Auth/ChangePassword";
import AuthRegistration from "../layouts/Auth/AuthRegistration";
import AuthLogin from "../layouts/Auth/AuthLogin";
import Dashboard from "../views/Dashboard";
import Staff from "../views/Staff";
import Home from "../views/Home";
import DashboardLayout from "../layouts/DashboardLayout";
import EmptyLayout from "../layouts/EmptyLayout";
import SplashScreen from "../views/SplashScreen";
import React from "react";
import ServicePage from "../views/ServicePage";
import Schedule from "../views/Schedule";
import Reviews from "../views/Reviews";
import Appointments from "../views/Appointments";
import Maps from "../views/Maps";
import Analytics from "../views/Analytics";
import BusinessInfo from "../views/BusinessInfo";
import Gallery from "../views/Gallery";
import Faq from "..//views/Faq";
import AuthProcess from "../views/Auth/AuthProcess";
import Success from "../views/Success";
import CRM from "../views/CRM";
import LoyaltyProgram from "../views/LoyaltyProgram";
import HowToRedeem from "../views/LoyaltyProgram/HowToRedeem";
import GiftLoyaltyProgram from "../views/LoyaltyProgram/Gift";
import VoucherLoyaltyProgram from "../views/LoyaltyProgram/Voucher";
import VoucherLoyaltyProgramPreview from "../views/LoyaltyProgram/VoucherPreview";
import ServiceQuote from "../views/ServiceQuote";
import CreateServiceQuote from "../views/ServiceQuote/CreateServiceQuote";
import QuoteDetails from "../views/ServiceQuote/QuoteDetails";
import EditInvoice from "../views/ServiceQuote/EditInvoice";

interface RouteProps {
  Component: React.Component | React.FunctionComponent;
  Layout?: React.Component | React.FunctionComponent | any;
  path: string;
  modules?: any;
}

const Pages = {
  HomePage: () => <Home />,
  StaffPage: () => <Staff />,
  ServicePage: () => <ServicePage />,
  LoginPage: () => <Login />,
  Register: () => <Register />,
  RegisterUser: () => <RegisterUser />,
  SplashScreen: () => <></>,
  Dashboard: () => <Dashboard />,
  Schedule: () => <Schedule />,
  Reviews: () => <Reviews />,
  Appointments: () => <Appointments />,
  Maps: () => <Maps />,
  Analytics: () => <Analytics />,
  BusinessInfo: () => <BusinessInfo />,
  Gallery: () => <Gallery />,
  Faq: () => <Faq />,
  ForgotPassword: () => <ForgotPassword />,
  ChangePassword: () => <ChangePassword />,
  AuthProcess: () => <AuthProcess />,
  Success: () => <Success />,
  CRM: () => <CRM />,
  Loyalty: () => <LoyaltyProgram />,
  HowToRedeem: () => <HowToRedeem />,
  GiftLoyaltyProgram: () => <GiftLoyaltyProgram />,
  VoucherLoyaltyProgram: () => <VoucherLoyaltyProgram />,
  VoucherLoyaltyProgramPreview: () => <VoucherLoyaltyProgramPreview />,
  ServiceQuote: () => <ServiceQuote />,
  CreateServiceQuote: () => <CreateServiceQuote />,
  QuoteDetails: () => <QuoteDetails />,
  EditInvoice: () => <EditInvoice />,
};

const Layouts = {
  LoginLayout: () => <AuthLogin children={<Login />} />,
  ForgotPasswordLayout: () => <AuthLogin children={<ForgotPassword />} />,
  ChangePasswordLayout: () => <AuthLogin children={<ChangePassword />} />,

  RegisterUserLayout: () => <AuthLogin children={<RegisterUser />} />,
  DashboardLayout: () => <DashboardLayout children={<Home />} />,
  HomeLayout: () => <DashboardLayout children={<Dashboard />} />,
  RegisterLayout: () => <AuthRegistration children={<Register />} />,
  AuthProcessLayout: () => <AuthLogin children={<AuthProcess />} />,
  DefaultLayout: () => <SplashScreen />,
  EmptyLayout: () => <EmptyLayout children={<Maps />} />,
};

const routes: RouteProps[] = [
  {
    path: "/welcome",
    Component: Pages.SplashScreen,
    Layout: Layouts.DefaultLayout,
  },
  {
    path: "/",
    Component: Pages.Dashboard,
    Layout: Layouts.HomeLayout,
  },
  {
    path: "dashboard",
    Component: Pages.HomePage,
    Layout: Layouts.DashboardLayout,
    modules: [
      {
        path: "staff",
        Component: Pages.StaffPage,
      },
      {
        path: "service",
        Component: Pages.ServicePage,
      },
      {
        path: "home",
        Component: Pages.Dashboard,
      },
      {
        path: "schedule",
        Component: Pages.Schedule,
      },
      {
        path: "reviews",
        Component: Pages.Reviews,
      },
      {
        path: "appointments",
        Component: Pages.Appointments,
      },
      {
        path: "analytics",
        Component: Pages.Analytics,
      },
      {
        path: "businessinfo/*",
        Component: Pages.BusinessInfo,
      },
      {
        path: "gallery",
        Component: Pages.Gallery,
      },
      {
        path: "faq",
        Component: Pages.Faq,
      },
      {
        path: "success",
        Component: Pages.Success,
      },
      {
        path: "CRM",
        Component: Pages.CRM,
      },
      {
        path: "loyalty-program",
        Component: Pages.Loyalty,
      },
      {
        path: "loyalty-program/how-to-redeem",
        Component: Pages.HowToRedeem,
      },
      {
        path: "loyalty-program/how-to-redeem/voucher",
        Component: Pages.VoucherLoyaltyProgram,
      },
      {
        path: "loyalty-program/how-to-redeem/gift",
        Component: Pages.GiftLoyaltyProgram,
      },
      {
        path: "loyalty-program/how-to-redeem/voucher/preview",
        Component: Pages.VoucherLoyaltyProgramPreview,
      },
      {
        path: "service-quote",
        Component: Pages.ServiceQuote,
      },
      {
        path: "service-quote/create",
        Component: Pages.CreateServiceQuote,
      },
      {
        path: "service-quote/:id",
        Component: Pages.QuoteDetails,
      },
      {
        path: "service-quote/edit/:id",
        Component: Pages.EditInvoice,
      },
    ],
  },
  {
    path: "map",
    Component: Pages.Maps,
    Layout: Layouts.EmptyLayout,
  },

  {
    path: "/auth/login",
    Component: Pages.LoginPage,
    Layout: Layouts.LoginLayout,
  },
  {
    path: "/auth/register/*",
    Component: Pages.Register,
    Layout: Layouts.RegisterLayout,
  },
  {
    path: "/auth/forgotpassword",
    Component: Pages.ForgotPassword,
    Layout: Layouts.ForgotPasswordLayout,
  },
  {
    path: "/auth/changepassword",
    Component: Pages.ChangePassword,
    Layout: Layouts.ChangePasswordLayout,
  },

  {
    path: "/auth/registeruser",
    Component: Pages.RegisterUser,
    Layout: Layouts.RegisterUserLayout,
  },

  {
    path: "/auth/start/*",
    Component: Pages.AuthProcess,
    Layout: Layouts.AuthProcessLayout,
  },
];

export default routes;
