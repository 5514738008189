import axios from "axios";

export enum QuoteStatus {
  Finalized = "finalized",
  Pending = "pending",
  Rejected = "rejected",
  Modified = "modified",
  Created = "created",
}

export type Role = "merchant" | "staff" | "freelancer";

export interface FetchQuoteParams {
  page: number;
  limit: number;
  keyword: string;
  where: object;
  order: "DESC" | "ASC";
}

export interface FetchQuoteResponse {
  data: Quote[];
  metadata: {
    pagination: {
      totalPages: number;
      totalCount: number;
    };
  };
}

export interface Quote {
  id: number;
  merchantId: number;
  customerId: number;
  staffId: number;
  status: QuoteStatus;
  merchantStatus: QuoteStatus;
  budget: number;
  date: string;
  price: number;
  requestedDate: string;
  requestedTime: string;
  reference: string;
  timeSlot: string;
  notes: string;
  paymentDescription: string;
  discount: number;
  taxRate: number;
  images: string[];
  createdAt: string;
  updatedAt: string;
  customer: {
    fullName: string;
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    uid: string;
    code: string;
    avatarUrl: string;
    phoneNumber: string;
    createdAt: string;
    updatedAt: string;
  };
  merchant: {
    id: 29;
    email: string;
    name: string;
    slug: string;
    live: boolean;
    code: string;
    userId: number;
    addressId: number;
    logo: string;
    categoryId: number;
    isVerified: boolean;
    description: string;
    platform: null;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
    address?: {
      id: number;
      stateId: number;
      city: string;
      street: string;
      longitude: number;
      latitude: number;
      postCode: null;
      createdAt: string;
      updatedAt: string;
    };
  };
  services: [
    {
      id: number;
      serviceId: number;
      quoteId: number;
      others: boolean;
      description: string;
      createdAt: string;
      updatedAt: string;
      service?: {
        label: string;
        id: number;
        businessId: number;
        name: string;
        price: number;
        duration: number;
        selectStaff: boolean;
        isHome: boolean;
        isLive: boolean;
        description: string;
        createdAt: string;
        updatedAt: string;
        deletedAt: null;
      };
    }
  ];
  logs: [
    {
      id: number;
      quoteId: number;
      modifiedBy: number;
      action: "created" | "modified";
      recipient: "merchant" | "customer";
      createdAt: string;
      updatedAt: string;
    }
  ];
  staff?: {
    id: number;
    role: Role;
    userId: number;
    businessId: number;
    active: boolean;
    profilePic?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
    designation?: string;
    user?: {
      id: number;
      uid: string;
      email: string;
      firstName: string;
      lastName: string;
      fullName: string;
      code: string;
      avatarUrl?: string;
      phoneNumber?: string;
    };
  };
}

export interface GetQuoteResponse {
  data: Quote;
}

export interface ModifyQuoteResponse {
  data: Quote;
  message: string;
}

export interface CreateServiceQuotePayload {
  payload: {
    customerInfo:
      | {
          firstName: string;
          lastName: string;
          email: string;
          phoneNumber: string;
        }
      | number;
    staffId?: number;
    timeSlot: string;
    date: string | Date;
    notes?: string;
    budget: number;
    discount: number;
    taxRate: number;
    price: number;
    images: string[];
  };
  services: Array<number | string>;
}

export interface ModifyServiceQuotePayload {
  payload: {
    staffId?: number;
    timeSlot: string;
    date: string | Date;
    notes?: string;
    discount: number;
    taxRate: number;
    price: number;
  };
  services: Array<number | string>;
}

export const fetchQuotes = async (
  params: FetchQuoteParams
): Promise<FetchQuoteResponse> => {
  const { page, keyword, limit, where, order } = params;
  const response = await axios.get<FetchQuoteResponse>(
    `/api/quote?where=${JSON.stringify(
      where
    )}&search=${keyword}&page=${page}&limit=${limit}&order=${order}`
  );
  return response.data;
};

export const fetchQuote = async (id: number): Promise<GetQuoteResponse> => {
  const response = await axios.get<GetQuoteResponse>(`/api/quote/${id}`);
  return response.data;
};

export const modifyQuote = async (
  id: number,
  payload: ModifyServiceQuotePayload
): Promise<ModifyQuoteResponse> => {
  const response = await axios.put<ModifyQuoteResponse>(
    `/api/quote/${id}`,
    payload
  );
  return response.data;
};
export const finalizeQuote = async (
  id: number,
  approved: boolean
): Promise<ModifyQuoteResponse> => {
  const response = await axios.patch<ModifyQuoteResponse>(
    `/api/quote/${id}/approve`,
    { approved }
  );
  return response.data;
};

export const createQuote = async (
  quote: CreateServiceQuotePayload
): Promise<Quote> => {
  const response = await axios.post<Quote>("/api/quote", quote);
  return response.data;
};
