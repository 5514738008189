import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import ModalContainer from "../ModalContainer";

interface CalendarModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDateSelect: (date: Date) => void;
}

const CalendarModal: React.FC<CalendarModalProps> = ({
  isOpen,
  onClose,
  onDateSelect,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  return (
    <ModalContainer modalOpen={isOpen} closeModal={onClose} size="large">
      <div className="p-6 flex justify-between items-center w-full">
        {/* Left Section: Title & Calendar */}
        <div className="flex flex-col items-start w-3/4">
          <h2 className="text-gray-900 font-semibold text-lg mb-4">
            When would you like to come in?
          </h2>

          {/* Calendar Component */}
          <Calendar
            value={selectedDate}
            onChange={(date: any) => setSelectedDate(date)}
            tileDisabled={({ date }) => date < new Date()}
            next2Label={null}
            prev2Label={null}
            className="custom-calendar"
          />
        </div>

        {/* Right Section: Proceed Button */}
        <div className="flex items-center justify-end w-1/4">
          <button
            className="bg-black text-white px-6 py-2 rounded-md flex items-center"
            onClick={() => {
              if (selectedDate) {
                onDateSelect(selectedDate);
              }
              onClose();
            }}
          >
            PROCEED →
          </button>
        </div>
      </div>
    </ModalContainer>
  );
};

export default CalendarModal;
