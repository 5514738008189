import React, { useState, useRef, useEffect } from "react";
import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import Select, { SingleValue } from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { PageHeader } from "../../components/Base/PageHeader";
import QuotePreview, {
  ServiceQuoteFormI,
} from "../../components/ServiceQuote/QuotePreview";
import { Icon } from "@iconify/react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectServices } from "../../features/pages/pages";
import { getMyServices } from "../../features/service/service";
import CalendarModal from "../../components/Modals/Quote/CalendarModal";
import ClientModal from "../../components/Modals/Quote/AddClientModal";
import {
  createQuote,
  CreateServiceQuotePayload,
} from "../../features/quote/service";
import {
  GetAvailableSlotParams,
  getAvailableSlots,
} from "../../features/operations/service";
import { selectCustomers } from "../../features/customer/reducer";
import { getClients } from "../../features/customer/service";
import { selectStaffs } from "../../features/staff/reducer";
import { getStaffs } from "../../features/staff/service";
import AddServiceModal from "../../components/Modals/AddServiceModal";
import {
  ServiceQuoteFormInitValues,
  // ServiceQuoteFormSchema,
} from "../../components/ServiceQuote/data";
import { useNavigate } from "react-router-dom";
import { Spinner } from "flowbite-react";

interface CustomerInfo {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}
function CreateServiceQuote() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const services = useAppSelector(selectServices);
  const customers = useAppSelector(selectCustomers);
  const staffs = useAppSelector(selectStaffs);

  const formikRef = useRef<FormikProps<ServiceQuoteFormI>>(null);

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [timeSlots, setTimeSlots] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const [loadingSlots, setLoadingSlots] = useState(false);

  const [clients, setClients] = useState<CustomerInfo[]>([]);
  const [openServiceModal, setOpenServiceModal] = useState(false);
  const [uploadedImages, setUploadedImages] = useState<File[]>([]);
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);

  const servicesOptions = [
    ...services.map((service) => ({
      value: service.id,
      label: service.name,
    })),
  ];

  useEffect(() => {
    dispatch(getMyServices());
    dispatch(getClients());
    dispatch(getStaffs());
  }, []);

  useEffect(() => {
    if (customers && customers.length) {
      setClients(
        customers.map((customer) => ({
          email: customer.customer.email,
          firstName: customer.customer.firstName,
          lastName: customer.customer.lastName,
          phone: customer.customer.phoneNumber,
        }))
      );
    }
  }, [customers]);

  // Fetch available time slots based on the selected date & Service
  const fetchTimeSlots = async (date: Date, serviceId: number | string) => {
    setLoadingSlots(true);
    try {
      const params: GetAvailableSlotParams = {
        serviceId,
        date: date.toISOString(),
      };
      const { data } = await getAvailableSlots(params);
      setTimeSlots(data.map((slot) => ({ value: slot, label: slot })));
    } catch (error) {
      alert("Failed to fetch time slots");
    } finally {
      setLoadingSlots(false);
    }
  };

  // Handle image file uploads
  const handleFileUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: any
  ) => {
    if (event.target.files) {
      const newImages = Array.from(event.target.files);
      setUploadedImages([...uploadedImages, ...newImages]);
      setFieldValue("supportingImages", [...uploadedImages, ...newImages]);
    }
  };
  const removeImage = (index: number, setFieldValue: any) => {
    const updatedImages = [...uploadedImages];
    updatedImages.splice(index, 1); // Remove the selected image
    setUploadedImages(updatedImages);
    setFieldValue("supportingImages", updatedImages);
  };

  const handleAddClient = (newClient: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  }) => {
    const client: CustomerInfo = {
      firstName: newClient.firstName,
      lastName: newClient.lastName,
      email: newClient.email,
      phone: newClient.phone,
    };

    setClients([client, ...clients]);
    formikRef.current?.setFieldValue("clientName", client.firstName);
    formikRef.current?.setFieldValue("clientLastName", client.lastName);
    formikRef.current?.setFieldValue("clientEmail", client.email);
    formikRef.current?.setFieldValue("clientPhone", client.phone);
  };

  const handleQuoteCreation = async (
    values: ServiceQuoteFormI,
    formikHelpers: FormikHelpers<any>
  ) => {
    const { resetForm } = formikHelpers;
    const {
      service,
      clientEmail,
      clientLastName,
      clientName,
      clientPhone,
      calendar,
      price,
      timeSlot,
      discount,
      notes: note,
      taxRate,
      staff,
    } = values;
    try {
      const payload: CreateServiceQuotePayload = {
        payload: {
          customerInfo: {
            email: clientEmail,
            firstName: clientName,
            lastName: clientLastName,
            phoneNumber: clientPhone,
          },
          staffId: staff,
          timeSlot,
          date: calendar,
          notes: note,
          budget: 0,
          discount,
          taxRate,
          price,
          images: [],
        },
        services: [service],
      };
      await createQuote(payload);
      resetForm();
      setIsSuccessPopupOpen(true);
    } catch (e) {
      alert("Failed to create quote");
    }
    console.log("Form submitted:", values);
  };

  return (
    <div className="w-full max-h-screen h-full flex justify-evenly gap-4 overflow-y-auto">
      <Formik
        innerRef={formikRef}
        initialValues={ServiceQuoteFormInitValues}
        // validationSchema={ServiceQuoteFormSchema}
        onSubmit={handleQuoteCreation}
      >
        {({ values, setFieldValue, errors, touched, isSubmitting }) => (
          <>
            <div className="w-full border rounded px-12 py-3 flex flex-col h-full">
              <PageHeader text="Quote Details" />
              <Form className="space-y-4 p-3 w-[70%]">
                {/* Service Selection */}
                <div>
                  <label className="block text-sm font-medium">Service *</label>
                  <div className="flex gap-4 w-full items-center">
                    <div className="w-full">
                      <Select
                        options={servicesOptions}
                        name="service"
                        onChange={(
                          selected: SingleValue<{
                            value: string;
                            label: string;
                          }>
                        ) => {
                          setFieldValue("service", selected?.value);
                        }}
                      />
                      {errors.service && touched.service && (
                        <p className="text-red-500 text-sm">{errors.service}</p>
                      )}
                    </div>
                    <Icon
                      className="cursor-pointer"
                      icon="material-symbols:add"
                      onClick={() => setOpenServiceModal(true)}
                    />
                  </div>
                </div>

                {/* Client Selection */}
                <div>
                  <label className="block text-sm font-medium">Client *</label>
                  <div className="flex gap-4 w-full items-center">
                    <div className="w-full">
                      <Select
                        options={clients
                          .map((client) => ({
                            value: client.email,
                            label: `${client.firstName} ${client.lastName}`,
                          }))
                          .concat({
                            label: "Add New Client",
                            value: "add-new-client",
                          })}
                        name="clientEmail"
                        onChange={(
                          selected: SingleValue<{
                            value: string;
                            label: string;
                          }>
                        ) => {
                          if (selected?.value === "add-new-client") {
                            setIsClientModalOpen(true);
                            return;
                          }
                          const client = clients.find(
                            (c) => c.email === selected?.value
                          );
                          if (client) {
                            setFieldValue("clientName", client.firstName);
                            setFieldValue("clientLastName", client.lastName);
                            setFieldValue("clientEmail", client.email);
                            setFieldValue("clientPhone", client.phone);
                          }
                        }}
                      />
                      {errors.clientName && touched.clientName && (
                        <p className="text-red-500 text-sm">
                          {errors.clientName}
                        </p>
                      )}
                    </div>
                  </div>
                  {/* Client Modal */}
                  <ClientModal
                    isOpen={isClientModalOpen}
                    onClose={() => setIsClientModalOpen(false)}
                    onAddClient={handleAddClient}
                  />
                </div>

                {/* Calendar Selection */}
                <div className="relative">
                  <label className="block text-sm font-medium">
                    Calendar *
                  </label>
                  <div className="flex items-center">
                    <input
                      type="text"
                      className="border rounded px-2 py-1 w-full"
                      value={selectedDate ? selectedDate.toDateString() : ""}
                      placeholder="Select a date"
                      readOnly
                      onClick={() => setIsCalendarOpen(true)}
                    />
                    <Icon
                      className="absolute right-3 text-gray-500 cursor-pointer"
                      icon="mdi:calendar"
                      onClick={() => setIsCalendarOpen(true)}
                    />
                  </div>
                  {errors.calendar && touched.calendar && (
                    <p className="text-red-500 text-sm">{errors.calendar}</p>
                  )}
                </div>

                {/* Calendar Modal */}
                <CalendarModal
                  isOpen={isCalendarOpen}
                  onClose={() => setIsCalendarOpen(false)}
                  onDateSelect={(date) => {
                    setSelectedDate(date);
                    formikRef.current?.setFieldValue("calendar", date);
                    if (values.service && date) {
                      fetchTimeSlots(date, values.service);
                    }
                  }}
                />

                {/* Staff */}
                <div>
                  <label className="block text-sm font-medium">
                    Staff (Optional)
                  </label>
                  <Field
                    as="select"
                    name="staff"
                    className="border rounded px-2 py-1 w-full"
                  >
                    {staffs.map((staff) => (
                      <option key={staff.id} value={staff.id}>
                        {staff.firstName} {staff.lastName}
                      </option>
                    ))}
                  </Field>
                </div>

                {/* Time Slot Selection */}
                <div>
                  <label className="block text-sm font-medium">Time Slot</label>
                  <Select
                    options={timeSlots}
                    name="timeSlot"
                    onChange={(
                      selected: SingleValue<{ value: string; label: string }>
                    ) => {
                      setFieldValue("timeSlot", selected ? selected.value : "");
                    }}
                    isLoading={loadingSlots}
                  />
                  {errors.timeSlot && touched.timeSlot && (
                    <p className="text-red-500 text-sm">{errors.timeSlot}</p>
                  )}
                </div>

                {/* Price */}
                <div>
                  <label className="block text-sm font-medium">Price</label>
                  <Field
                    type="number"
                    name="price"
                    className="border rounded px-2 py-1 w-full"
                  />
                  {errors.price && touched.price && (
                    <p className="text-red-500 text-sm">{errors.price}</p>
                  )}
                </div>

                {/* Apply Discount */}
                <div>
                  <label className="block text-sm font-medium">
                    <Field type="checkbox" name="applyDiscount" />
                    <span className="mx-2">Apply Discount</span>
                  </label>
                  {values.applyDiscount && (
                    <Field
                      type="text"
                      name="discount"
                      className="border rounded px-2 py-1 w-full"
                      placeholder="Enter discount"
                    />
                  )}
                  {errors.discount && touched.discount && (
                    <p className="text-red-500 text-sm">{errors.discount}</p>
                  )}
                </div>

                {/* Apply Tax Rate */}
                <div>
                  <label className="block text-sm font-medium">
                    <Field type="checkbox" name="applyTaxRate" />
                    <span className="mx-2">Apply Tax Rate</span>
                  </label>
                  {values.applyTaxRate && (
                    <Field
                      type="text"
                      name="taxRate"
                      className="border rounded px-2 py-1 w-full"
                      placeholder="Enter tax rate"
                    />
                  )}
                  {errors.taxRate && touched.taxRate && (
                    <p className="text-red-500 text-sm">{errors.taxRate}</p>
                  )}
                </div>

                {/* Note */}
                <div>
                  <label className="block text-sm font-medium">Note</label>
                  <Field
                    as="textarea"
                    name="note"
                    className="border rounded px-2 py-8 w-full"
                  />
                </div>

                {/* Payment Instruction */}
                <div>
                  <label className="block text-sm font-medium">
                    Payment Instruction
                  </label>
                  <Field
                    as="textarea"
                    name="paymentDescription"
                    className="border rounded px-2 py-8 w-full"
                  />
                </div>

                {/* Supporting Images */}
                <div className="mt-4">
                  <label className="block text-sm font-medium">
                    Supporting Images
                  </label>
                  <div className="flex items-center gap-4">
                    {/* File Upload Box */}
                    <label className="flex flex-col items-center justify-center w-20 h-20 border rounded-lg cursor-pointer bg-blue-950">
                      <Icon icon="mdi:plus" className="text-white text-2xl" />
                      <span className="text-xs text-white">Add Photo</span>
                      <input
                        type="file"
                        multiple
                        accept="image/*"
                        className="hidden"
                        onChange={(e) => handleFileUpload(e, setFieldValue)}
                      />
                    </label>

                    {/* Uploaded Images Preview */}
                    <div className="flex gap-2">
                      {uploadedImages.map((image, index) => (
                        <div key={index} className="relative">
                          <img
                            src={URL.createObjectURL(image)}
                            alt={`upload-${index}`}
                            className="w-20 h-20 rounded-md object-cover border"
                          />
                          {/* Remove Button */}
                          <button
                            className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full text-xs w-5 h-5 flex items-center justify-center shadow-md"
                            onClick={() => removeImage(index, setFieldValue)}
                          >
                            ✕
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Cancel & Finalize Buttons */}
                <div className="flex justify-end gap-6 mt-6">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="px-6 py-2 bg-green-600 text-white rounded-md font-bold hover:bg-green-700 uppercase"
                  >
                    {isSubmitting ? <Spinner /> : "Create Quote"}
                  </button>
                </div>
              </Form>
            </div>
            <QuotePreview {...values} />
          </>
        )}
      </Formik>

      <AddServiceModal
        modalOpen={openServiceModal}
        closeModal={() => {
          dispatch(getMyServices());
          setOpenServiceModal(false);
        }}
      />

      {/* Success Popup */}
      {isSuccessPopupOpen && (
        <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50">
          <div className="bg-white p-10 w-[500px] h-[300px] rounded-xl shadow-lg flex flex-col items-center gap-4">
            {/* Green Check Icon */}
            <Icon
              icon="mdi:check-circle-outline"
              className="text-green-500 text-9xl"
            />

            {/* Success Message */}
            <p className="text-black-800 text-lg font-semibold text-center">
              Invoice has been created successfully.
            </p>

            {/* Close Button */}
            <button
              className="bg-black text-white px-6 py-3 rounded-md font-semibold hover:bg-gray-900"
              onClick={() => {
                navigate("/dashboard/service-quote");
                setIsSuccessPopupOpen(false);
              }}
            >
              VIEW INVOICE DETAILS
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CreateServiceQuote;
