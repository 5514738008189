import axios from "axios";
import { useEffect, useState } from "react";
import { PageHeader } from "../components/Base/PageHeader";
import { SkeletonServices } from "../components/CardSkeleton";
import AddServiceModal from "../components/Modals/AddServiceModal";
import NewEmptyState from "../components/NewEmptyState";
import Service from "../components/Service";
import ImagePlaceholder from "../assets/images/emptyService.svg";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { selectServices, SET_SERVICES } from "../features/pages/pages";

const Staff = () => {
  const dispatch = useAppDispatch();
  const services = useAppSelector(selectServices);
  const [modalOpen, setModalOpen] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const getAllServices = async () => {
    try {
      !services.length && setFetch(true);
      const response = await axios.get(`/api/business/service`);
      dispatch(SET_SERVICES(response.data.data));
    } catch (e) {
      console.error(e);
    } finally {
      setFetch(false);
    }
  };

  useEffect(() => {
    getAllServices();
  }, [refreshPage]);
  return (
    <div className="h-screen overflow-x-hidden">
      <PageHeader
        text="Services"
        buttontext="create"
        buttonevent={() => setModalOpen(true)}
      />

      {!fetch ? (
        services.length ? (
          <div className="grid grid-cols-4 gap-5 my-5">
            {services.map((service) => (
              <Service
                service={service}
                key={service.id}
                refresh={() => setRefreshPage((previous) => !previous)}
              />
            ))}
          </div>
        ) : (
          <NewEmptyState
            text=" You dont have any  services"
            buttonText="Create One"
            handleClick={() => {
              setModalOpen(true);
            }}
            image={ImagePlaceholder}
          />
        )
      ) : (
        <div className="grid grid-cols-4 gap-5 my-5">
          {[...Array(14)].map((_, i) => {
            return <SkeletonServices key={i} />;
          })}
        </div>
      )}

      {modalOpen && (
        <AddServiceModal
          modalOpen={modalOpen}
          closeModal={() => {
            setRefreshPage((previous) => !previous);
            setModalOpen(!modalOpen);
          }}
        />
      )}
    </div>
  );
};

export default Staff;
